import React from 'react'
import styled from 'styled-components'
import Orders from '../../components/cards/Orders'
import KeyValueGroup from '../../components/KeyValueGroup'
import Number from '../../components/Number'
import Prognosis from './Prognosis'
import PrognosisGraph from './PrognosisGraph'
import SalesGraph from './SalesGraph'

export default function PrognosisCard({data}) {


    let fourWeekPositive = data?.forecast.fourWeeksAveragePercent >= 1
    let eightWeekPositive = data?.forecast.eightWeeksAveragePercent >= 1



    return (
        <Style className="prognosis-card card">

            <div className="left-panel">
                <h2 className="color-blue-gray">Prognosis</h2>
                <PrognosisGraph data={data} />
            </div>

            <div className="right-panel">

                <div className="column">

                    <KeyValueGroup 
                        label="in stock"
                        primaryValue={data?.stock.stockAmount}
                        secondaryValue={data?.stock.totalSEK}
                    />

                    {data?.quarterStock?.stockAmount && <>
                        <div className="spacer s"></div>
                        <KeyValueGroup 
                            label="in stock (1/4)"
                            secondaryValue={data?.quarterStock.stockAmount}
                            secondaryUnit='pcs'
                        />
                    </>}

                    <div className="spacer s"></div>

                    <KeyValueGroup 
                        label="plan stock for"
                        secondaryValue={data?.weeksPlanning}
                        secondaryUnit='weeks'
                    />

                    <div className="spacer s"></div>

                    <KeyValueGroup 
                        label="in stock for"
                        secondaryValue={data?.weeksStock}
                        secondaryUnit='weeks'
                    />
                
                    
                </div>

                <div className="column">

                    
                    <KeyValueGroup 
                        label={`annual prognosis (${new Date().getFullYear()})`}
                        primaryValue={data?.annual.prognosis}
                        primaryUnit='pcs'
                    />

                    <div className="spacer s"></div>

                    <KeyValueGroup 
                        label="annual sales ytd"
                        primaryValue={data?.annual.sold}
                        primaryUnit='pcs'
                    />

                </div>


                <div className="column prognosis">

                    <Prognosis data={data} />


                </div>

            </div>

            <Orders data={data}/>


        </Style>
    )
}

const Style = styled.div`
    padding: 15rem;

    background: #272727 !important;
    border-color: #343434;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20rem;


    .right-panel {
        padding-top: 30rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .prognosis {
            // margin-top: 30rem;
            // grid-column: 1 / 3;

            .data {
                // display: flex;
            }
        }
    }

    .mobile & {
        grid-template-columns: minmax(0, 1fr);
        .right-panel {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            row-gap: 30px;
        }
    }
`