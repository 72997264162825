import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import Arrow from '../../components/Arrow'
import { useDevices } from '../../util/Util';
import { ProductContext } from './Product'

export default function Sidebar() {

    const history = useHistory();

    const [mobile] = useDevices();

    const {view, setView, open, setOpen} = useContext(ProductContext)

    const views = [
        {name: 'Overview', key: 'all'},
        {name: 'Sales', key: 'sales'},
        {name: 'Prognosis', key: 'prognosis'},
        {name: 'Distribution', key: 'stock'},
    ]

    return (
        <Style className={`${open ? 'open' : 'closed'}`}>

            {!mobile && <div className="back" onClick={() => history.push('/')}>
                <Arrow left/>
                <h2 className="color-blue-gray">All Products</h2>
            </div>}

            <h2 className="color-blue-gray">View</h2>

            <ul>
                {views.map(v => (
                    <li 
                        key={v.key} 
                        className={`numbers ${view == v.key ? 'selected' : ''}`}
                        onClick={() => {
                            setView(v.key)
                            setOpen(false)
                            setTimeout(() => window.scrollTo({y: 0}), 0)
                        }}
                    >
                        {v.name}
                    </li>
                ))}
            </ul>
        </Style>
    )
}

const Style = styled.div`

    grid-column: span 2;

    .back {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 15rem;

        .arrow {
            font-size: 20rem;
            margin-right: 10rem;
        }
    }

    .tablet & {
        grid-column: span 6;
        padding: 15rem;

        ul {
            display: flex;
            padding-left: 0px;

            li {
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }


    ul {
        list-style: none;
        margin-top: 22px;
        padding-left: 32px;

        li {
            margin-bottom: 10px;
            cursor: pointer;
            user-select: none;

            &.selected {
                color: var(--color-orange);
            }
        }
    }



    .mobile & {
        position: fixed;
        top: 0;
        left: 100vw;
        width: 100%;
        height: 100%;
        z-index: 100;

        transition: left 300ms;
        will-change: left;

        padding: 85px 20px;

        border: blue dashed 1px;
        background: var(--color-background);

        ul {
            padding-left: 0;
        }        

        &.open {
            left: 0;
        }

    }


`