import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import TuesdayWine from '../../util/TuesdayWine';

export default function AnalysisRow({ order, canDelete, onDeleted, onStatusUpdated, component }) {
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)

    const history = useHistory();

    const analysis = order.analysis[0];
    const ongoing = analysis != null;

    const isPastFillingDate = new Date(order.order.filling_date) < new Date() && analysis?.status !== 'completed';

    function handleShow() {
        history.push(`/analyze/casas/${analysis.id}`)
    }

    async function handleCreate() {
        setLoadingCreate(true)

        let res = await TuesdayWine.post(`/analyze`, {
            order_id: order.order_id
        })
        setLoadingCreate(false)

        if (res === undefined || res.analysis === undefined) {
            return alert('Failed to create analysis');
        }

        history.push(`/analyze/casas/${res.analysis}`)
    }

    async function handleDelete() {

        const confirm = window.confirm('Are you sure you want to delete this analysis?')
        if (!confirm) return;

        setLoadingDelete(true)

        let res = await TuesdayWine.delete(`/analyze/${analysis.id}`)
        setLoadingDelete(false)

        console.log(res)

        if (res === undefined || res.success !== true) {
            return alert('Failed to delete analysis');
        }

        if (onDeleted) onDeleted(analysis.id)
    }

    async function handleSetStatus(status) {
        setLoadingStatus(true)
        let res = await TuesdayWine.put(`/analyze/${analysis.id}/${status}`)
        setLoadingStatus(false)
        if (res === undefined || res.success !== true) {
            return alert('Failed to set status');
        }

        if (onStatusUpdated) onStatusUpdated(analysis.id, status)
    }

    if (component) {
        return component({
            order,
            canDelete,
            onDeleted,
            handleShow,
            handleCreate,
            handleDelete,
            loadingCreate,
            loadingDelete,
            ongoing
        })
    }

    return (
        <tr>
            <td>{order.order.cp_order_no}</td>
            <td>{order.order.globus_order_no}</td>
            <td>
                <a href={`https://kylix.skanlog.com/kylix/purchase_pkg.purchase_order_detail?p_order_no=${order.kylix_order_nr}`} target="_blank" rel="noreferrer" style={{color:'white'}}>
                    {order.order.tuesday_order_no}
                </a>
            </td>
            <td>{analysis?.lot}</td>
            <td>{new Date(order.order.eta_stockholm).toLocaleDateString('sv-SE', {
                year: 'numeric', month: 'numeric', day: 'numeric',
            })}</td>
            <td>{ongoing ? new Date(analysis.created_at).toLocaleDateString('sv-SE', {
                year: 'numeric', month: 'numeric', day: 'numeric',
            }) : '-'}</td>
            <td style={{
                color: isPastFillingDate ? 'red' : undefined
            }} className={`${isPastFillingDate ? 'past-filling-date' : ''}`}>{new Date(order.order.filling_date).toLocaleDateString('sv-SE', {
                year: 'numeric', month: 'numeric', day: 'numeric',
            })}</td>
            <td>{order.order.wine ?? '-'}</td>
            <td>
                <div className="status-lights">
                    {ongoing ? ['casas', 'globus_arrival', 'globus_departure', 'tuesdaywine'].map(name => {
                        const completed = analysis.completed_names.includes(name);

                        if (completed) {
                            return (
                                <div className="completed"></div>
                            )
                        } else {
                            return (
                                <div className="incomplete"></div>
                            )
                        }
                    }) : null}
                </div>
            </td>
            <td>
                {ongoing && <button onClick={handleShow}>Show</button>}
                {!ongoing && <button className={`${loadingCreate ? 'loading' : ''}`} onClick={handleCreate}>Create</button>}
            </td>
            <td>
                {canDelete && ongoing && <button className={`${loadingDelete ? 'loading' : ''}`} onClick={handleDelete}>Delete</button>}
            </td>
            <td>
                {canDelete && ongoing && analysis?.status === "completed" && analysis?.manually_completed && <button className={`${loadingStatus ? 'loading' : ''}`} onClick={() => handleSetStatus('pending')}>Set ongoing</button>}
                {canDelete && ongoing && analysis?.status !== "completed" && !analysis?.manually_completed && <button className={`${loadingStatus ? 'loading' : ''}`} onClick={() => handleSetStatus('completed')}>Set completed</button>}
            </td>
        </tr>
    )
}
