import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import ProductView from '../components/ProductView';
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';
import ReactGA from 'react-ga';
import Logo from '../components/Logo';
import TVProduct from './TVProduct/TVProduct';

export default function Screen({match}) {


    let {id} = match.params;

    const [productNumber, setProductNumber] = useState()
    const [data, setData] = useState()
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (id != 5) return;
		ReactGA.pageview('/screen/' + id)
        setLoader({visible: true, message: `Laddar produkter...`});
        (async () => {
            setProducts(await TuesdayWine.getProducts())
            setLoader((loader) => ({...loader, visible: false}));
        })()
    }, [])

    useEffect(() => {
        let lastIndex = -1;
        let check = async () => {
            let index = Math.floor(((new Date().getTime() / 1000) / 10) % products.length);
            if (index != lastIndex) {
                if (products[index]?.art_nr_full)
                    setProductNumber(products[index]?.art_nr_full);
                lastIndex = index;
            }
        }

        check();
        let interval = setInterval(check, 100)
        return () => {
            clearInterval(interval);
        }
    }, [products])
    
    const {loader, setLoader} = useContext(LoaderContext);

    let groups = [

        [
            '1209401/12',
            '636701/12E',
            '208801/10A',
            '263401/12',
        ],

        [
            '1209408/4A',
            '647408/4',
            '689608/4A',
            '272921/12',
        ],

        [
            '209908/4A',
            '659508/4',
            '6896X8/56A',
            '2006311/10',
        ],

        [
            '208601/6A',
            '689508/4A',
            '213008/4A',
            '114308/4',
        ],

        [
            'EMPTY',
            '6895X8/56A',
            '2130X8/56A',
            '260208/4',
        ]

    ]


    // Look for the product number.
    useEffect(() => {
        document.title = "SCREEN_" + id
        if (id == 5) return;


        const getProductNumber = async (id) => {
            let res = await TuesdayWine.get(`/screen/${id}`, true);
            if (res == undefined) return;
            return res['art_nr'];
        }

        setLoader({visible: true, message: `Laddar skärm: ${id}`});
        
        let groupInterval;
        let ppn = undefined;

        const checkDatabase = (async () => {
            if (new Date().getHours() > 21 || new Date().getHours() < 3) return;
            let pn = await getProductNumber(id);


            if (pn != ppn) {
                if (pn == 'auto') {
                    console.log('AUTO');

                    if (groupInterval == undefined) {

                        let lastIndex = -1;
                        let check = async () => {
                            let index = Math.floor(((new Date().getTime() / 1000) / 30) % groups.length);
                            if (index != lastIndex) {
                                console.log(index)
                                if (groups[index][id - 1])
                                    setProductNumber(groups[index][id - 1]);
                                lastIndex = index;
                            }
                        }

                        check();
                        groupInterval = setInterval(check, 100)
                    }



                } else if (pn == 'empty') {

                    console.log('EMPTY');
                    setProductNumber('EMPTY');
                    setLoader((loader) => ({...loader, visible: false}));

                } else {
                    if (groupInterval) clearInterval(groupInterval);
                    groupInterval = undefined;
                    setProductNumber(pn)
                    setLoader((loader) => ({...loader, visible: false}));
                }
            }

            ppn = pn;

        })

        checkDatabase();
        let interval = setInterval(checkDatabase, 5 * 1000)


        return () => {
            groupInterval = undefined;
            clearInterval(interval);
            if (groupInterval) clearInterval(groupInterval);
        }
    }, [id])


    // Load the product data.
    useEffect(() => {

        
        if (productNumber == undefined) return;
        
        if (productNumber == 'EMPTY') {
            setLoader((loader) => ({...loader, visible: false}));
            return;
        }
        
        setLoader({visible: true, message: `Förbereder produkt: ${productNumber}`});

        (async () => {
            setData(await TuesdayWine.getSummary(productNumber))
            setLoader((loader) => ({...loader, visible: false}));
        })()

    }, [productNumber])

    console.log(productNumber);

    return (
        <Style>
            {/* {productNumber != 'EMPTY' && <ProductView data={data} productNumber={productNumber ? productNumber.split('/')[0] : ''} packageNumber={productNumber ? productNumber.split('/')[1] : ''}></ProductView>} */}
            {productNumber != 'EMPTY' && <TVProduct data={data} art_nr={productNumber} />}

            {productNumber == 'EMPTY' && (
                <Logo style={{width: 300}}></Logo>
            )}
        </Style>
    )
}


const Style = styled.main`

    & > .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35em;
    }

`