import React, { useState } from 'react'
import styled from 'styled-components'
import KeyValueGroup from '../../components/KeyValueGroup'
import Number from '../../components/Number'
import RadialSalesGraph from './RadialSalesGraph'
import SalesGraph from './SalesGraph'


export default function SalesCard({data}) {

    const [viewBars, setViewBars] = useState(true)

    return (
        <Style className={`sales-card card`}>

            <div className="left-panel">
                <div className="row center">
                    <h2 className="color-blue-gray">Sales</h2>
                    <div className="spacer ws"></div>
                    <button onClick={() => setViewBars(!viewBars)}>{viewBars ? 'Show circles' : 'Show bars'}</button>
                </div>
                {viewBars && <SalesGraph data={data} />}
                {!viewBars && <RadialSalesGraph data={data} />}
            </div>

            <div className="right-panel">

                <KeyValueGroup
                    label="today"
                    primaryValue={data?.soldToday.amountSold}
                    secondaryValue={data?.soldToday.totalSEK}
                />

                <KeyValueGroup
                    label="sold this month"
                    primaryValue={data?.soldThisMonth.amountSold}
                    secondaryValue={data?.soldThisMonth.totalSEK}
                />


                <KeyValueGroup
                    label="last month this day"
                    primaryValue={data?.previousMonthThisDay.amountSold}
                    secondaryValue={data?.previousMonthThisDay.totalSEK}
                />

                <KeyValueGroup
                    label="last month total"
                    primaryValue={data?.soldLastMonth.amountSold}
                    secondaryValue={data?.soldLastMonth.totalSEK}
                />

                <KeyValueGroup
                    label="last month to this day"
                    primaryValue={data?.previousMonthToThisDay.amountSold}
                    secondaryValue={data?.previousMonthToThisDay.totalSEK}
                />

            </div>

        </Style>
    )
}

const Style = styled.div`
    padding: 15rem;

    background: #272727 !important;
    border-color: #343434;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20rem;


    .right-panel {
        padding-top: 20rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }




    .mobile & {
        grid-template-columns: minmax(0, 1fr);
        .right-panel {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            row-gap: 30px;
        }
    }

`