import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ProductViewComponent from '../components/ProductView';
import Card from '../components/Card';
import DynamicText from '../components/DynamicText';
import TuesdayWine from '../util/TuesdayWine';
import { useAspectRatio } from '../util/Util';
import SoldToday from '../components/cards/SoldToday';
import PreviousMonthToThisDay from '../components/cards/PreviousMonthToThisDay';
import PreviousMonthThisDay from '../components/cards/PreviousMonthThisDay';
import SoldThisMonth from '../components/cards/SoldThisMonth';
import Annual from '../components/cards/Annual';
import Prognosis from '../components/cards/Prognosis';
import InStockFor from '../components/cards/InStockFor';
import PlanStockFor from '../components/cards/PlanStockFor';
import TotalStores from '../components/cards/TotalStores';
import PremiumStores from '../components/cards/PremiumStores';
import InStock from '../components/cards/InStock';
import SoldLastMonth from '../components/cards/SoldLastMonth';
import Stores from '../components/cards/Stores';
import StandardStores from '../components/cards/StandardStores';
import QuarterStock from '../components/cards/QuarterStock';
import SalesGraph from '../components/charts/SalesGraph';
import ForecastGraph from '../components/charts/ForecastGraph';
import Logo from '../components/Logo';
import ProductViewInfo from '../components/ProductViewInfo';
import Orders from '../components/cards/Orders';
import Loader from '../components/Loader';
import LoaderContext from '../context/LoaderContext';
import { useHistory } from 'react-router-dom';



export default function ProductView({data, productNumber, packageNumber}) {


    


    const asp = useAspectRatio();
    const wide = asp > 1.95;
    const ultraWide = asp > 2.4;


    return (
        <Style>

            <div className="graphs">

                <ProductViewInfo data={data} artNr={productNumber + '/' + packageNumber}></ProductViewInfo>
                
                <div className="graph-wrapper">
                    <ForecastGraph data={data}></ForecastGraph>
                    <div className="spacer m"></div>
                    <SalesGraph data={data} isPallet={productNumber[productNumber.length - 2] == 'X'}></SalesGraph>
                </div>

                {!wide && <>
                    <div className="spacer m"></div>
                    <Orders data={data}></Orders>
                </>}
            </div>

            <div className="cards" style={{fontSize: wide ? `0.85em` : `0.85em`}}>

                <SoldToday mobileOrder={1} w={wide ? 1 : 1.5} h={wide ? 1 : 2} scale={wide ? 0.8 : 1} data={data}></SoldToday>

                <PreviousMonthThisDay mobileOrder={4} data={data}></PreviousMonthThisDay>

                <Prognosis isPallet={productNumber[productNumber.length - 2] == 'X'} mobileOrder={9} data={data} w={wide ? 1 : 1} h={wide && 1} scale={wide && 1} label="prognosis"></Prognosis>

                <InStockFor mobileOrder={7} data={data}></InStockFor>
                
                <PlanStockFor mobileOrder={8} data={data}></PlanStockFor>



                <PreviousMonthToThisDay mobileOrder={5} data={data}></PreviousMonthToThisDay>

                <TotalStores mobileOrder={11} data={data}></TotalStores>


                <PremiumStores mobileOrder={13} data={data}></PremiumStores>

                <InStock mobileOrder={6} data={data} w={wide ? 1 : 0.5}></InStock>



                <SoldThisMonth mobileOrder={2} w={wide ? 1 : 0.5} data={data}></SoldThisMonth>

                <SoldLastMonth mobileOrder={3} w={wide ? 1 : 0.5} data={data}></SoldLastMonth>

                {TuesdayWine.lock([1,2]) &&
                    <Annual mobileOrder={14} w={wide ? 1 : .5} h={wide ? 1 : 1} scale={wide && 1.5} data={data}></Annual>
                }

                <Stores mobileOrder={10} data={data}></Stores>
                
                <StandardStores mobileOrder={12} data={data}></StandardStores>

                {data && data.quarterStock.stockAmount && (
                    <QuarterStock mobileOrder={15} data={data}></QuarterStock>
                )}

            </div>
                
            {wide && <>
                <Orders data={data}></Orders>
            </>}
        </Style>
    )
}


const Style = styled.main`


    display: flex;
    padding: 0.5em;
    height: 100vh;
    overflow: auto;

    .graphs {
        overflow: auto;
        padding: 0em 2em;
        width: 50%;
        flex-shrink: 0;
        // max-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .cards {
        overflow: auto;
        flex-grow: 1;
        display: grid;
        gap: 0.5em;
        // grid-template-columns: repeat(3, 1fr);
        // grid-auto-flow: row;
        grid-template-rows: repeat(6, auto);
        grid-auto-flow: column;
        // display: none;
    }

    

    body.tablet &, body.mobile & {

        .graphs {
            padding: 0em;
            width: 100%;
            margin-bottom: 0.5em;
        }


        height: unset;
        flex-direction: column;

        .cards {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-auto-flow: row;
        }
    }

    body.mobile & {

        

        .cards {
            grid-template-columns: repeat(1, 1fr);

            .card {
                grid-column: auto / span 1;
                grid-row-end: span 1;
            }
        }
    }

    body.wide:not(.desktop) & {
        .graphs {
            width: 100%;
        }
    }

    body.desktop.wide & {

        flex-direction: column;
        height: unset;
        align-items: center;
        padding: 2em;

        & > *:not(:last-child) {
            margin-bottom: 2em;
        }


        .graphs {
            width: 100%;
            overflow: unset;
            padding: unset;

            .product-info {
                font-size: 1em !important;
            }

            .graph-wrapper {
                display: flex;
                justify-content: space-between;

                & > *:not(.spacer) {
                    width: 49%;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }

        .cards {
            overflow: unset;
            width: 100%;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: unset;
            grid-auto-flow: row;
        }

        .orders {
            width: 100%;
            flex-shrink: 0;
            height: auto;
        }
    }


    body.ultra-wide & {
        .graphs {
            width: 40%;
        }

        .cards {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-auto-flow: row;
        }
    }


`