import { sha256 } from 'js-sha256';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import AdminOrderRow from '../components/AdminOrderRow';
import Icon from '../components/Icon';
import Table from '../components/Table';
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';

export default function Orders() {
    
    const {loader, setLoader, loggedIn} = useContext(LoaderContext);
    const history = useHistory();

    const [orders, setOrders] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    useEffect(async () => {
        await TuesdayWine.post('/orders')
        setOrders(await TuesdayWine.get('/orders'))
        setLoader(false)
    }, [])

    const load = async () => {
        setLoading(true)
        await TuesdayWine.post('/orders')
        setOrders(await TuesdayWine.get('/orders'))
        setLoading(false)
    }

    const exportExcel = async () => {
        setLoadingExport(true)
        

        let blob = await (await fetch(`${TuesdayWine.getURL()}/orders/export`, {
            method: 'GET',
            // body: JSON.stringify({
            // }),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('user')}`
            },
        })).blob();

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url;
        a.download = 'export.xlsx';
        document.body.appendChild(a)
        a.click()
        a.remove()

        setLoadingExport(false)
    }


    if (!orders) return <></>
    return (
        <Style className={`layout-container`}>

            <div className="spacer l"></div>

            <div className="orders card">
                <div className="row">
                    <h2 className="color-blue-gray">Orders</h2>
                    <div className="spacer auto"></div>
                    <button onClick={exportExcel} className={loadingExport && 'loading'}>Download Excel</button>
                    <div className="spacer ws"></div>
                    <button onClick={load} className={loading && 'loading'}>Refresh order status</button>
                </div>

                <div className="spacer m"></div>

                <table>
                    <thead>
                        <tr>
                            <th>TW</th>
                            <th>Wine</th>
                            <th>Skanvin</th>
                            <th>Casas</th>
                            <th>Planned ETD from</th>
                            <th>Load from Bordegia</th>
                            <th>JFH Status</th>
                            <th>DIFF</th>
                            <th>JFH ETD Date</th>
                            <th>ETA Stockholm</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {orders.map(order => <AdminOrderRow 
                            key={order.tuesday_order_no} 
                            order={order} 
                            onChange={order => setOrders(o => {
                                let tmp = [...o];
                                let orderIndex = tmp.findIndex(o => o.tuesday_order_no == order.tuesday_order_no);
                                tmp[orderIndex] = order;
                                return tmp;
                            })}
                        />)}
                    </tbody>
                </table>


            </div>

        </Style>
    )
}

const Style = styled.main`

    width: 1600px;

    .orders {
        grid-column: 1 / 13;

        table {
            th {
                text-align: left;
            }

            tr {
                td {

                    &.title-row {
                        max-width: 80rem;
                        font-size: 10rem;
                        padding-right: 20rem;
                        font-weight: normal;
                    }

                    input {

                        &[type="date"] {
                            &::-webkit-calendar-picker-indicator {
                                filter: invert(1);
                            }
                        }

                        &.warning {
                            border: red solid 1px;
                        }
                        &.warning-text {
                            color: red;
                        }
                        &.changed {
                            border: yellow solid 1px;
                        }

                        &.loading-week {
                            width: 40rem;
                        }
                    }
                }
            }
        }
    }


`