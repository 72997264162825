import React from 'react';

export const MESSAGES = {

    // Robertson Chardonnay PET
    '1209401': (
        <>
            <p>Target ethanol value is 13.50%</p>
        </>
    ),

    // Robertson Chardonnay
    '1209408': (
        <>
            <p>Target ethanol value is 13.50%</p>
        </>
    ),

    // Robertson Cabernet Sauvignon
    '209908': (
        <>
            <p>Target ethanol value is 13.00%</p>
        </>
    ),

    // Casas Patronales Rich
    '213008': (
        <>
            <p>Target ethanol value is 13.50%</p>
        </>
    ),

    // Casas Patronales Crisp
    '689508': (
        <>
            <p>Target ethanol value is 13.00%</p>
        </>
    ),

    // Casas Patronales Rose
    '689608': (
        <>
            <p>Target ethanol value is 13.00%</p>
        </>
    ),
}