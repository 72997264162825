import React, { createContext, useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import TuesdayWine from '../../util/TuesdayWine';
import LoaderContext from '../../context/LoaderContext';
import Sidebar from './Sidebar';
import ProductsCard from './ProductsCard';
import { useDevices } from '../../util/Util';
import Users from '../Users';
import SBImages from '../SBImages/SBImages';

// ProductsContext holds data for child components.
export const ProductsContext = createContext();

const menuItems = [
    {type: 'category', name: 'All Products', key: 'all'},
    {type: 'category', name: 'Red Wines', key: 'red'},
    {type: 'category', name: 'White Wines', key: 'white'},
    {type: 'category', name: 'Rosé Wines', key: 'rose'},
    {type: 'category', name: 'Box Wines', key: 'box'},
    {type: 'category', name: 'Logout', key: 'logout'},
    
    {type: 'admin', name: 'Users', key: 'users'},
    {type: 'admin', name: 'SB Images', key: 'sb_images'},
    {type: 'admin', name: '---', key: '', disabled: true},
    {type: 'admin', name: 'Clear Cache', key: 'clear_cache'},
    {type: 'admin', name: 'Refresh DATA file', key: 'refresh_data_file'},
]

export default function ProductList() {

    const history = useHistory()

    const {setLoggedIn, setLoader} = useContext(LoaderContext);

    const [mobile, tablet, desktop] = useDevices();
    
    // Store loaded list of products.
    const [products, setProducts] = useState([]);

    // Store currently selected category.
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0])

    // Initial page load.
    useEffect(() => {

        // Enable the loader.
        setLoader({visible: true, message: `Laddar produkter...`});
        
        (async () => {
            
            // Load products from API.
            setProducts(await TuesdayWine.getProducts(true))
            
            // Remove loader.
            setLoader((loader) => ({...loader, visible: false}));
        })()
    }, [])

    useEffect(() => {
        if (selectedMenuItem.key == 'logout') {
            localStorage.removeItem('user');
            setLoggedIn(false);
        }
        if (selectedMenuItem.key == 'clear_cache') {
            TuesdayWine.post('/manual/clean-cache');
            setSelectedMenuItem(menuItems[0])
            alert('Serverns cache har rensats, ladda om sidan för att generera om data för alla produkter. OBS! Detta kan ta ett par minuter!')
        }
        if (selectedMenuItem.key == 'refresh_data_file') {
            TuesdayWine.post('/manual/restart-services');
            setSelectedMenuItem(menuItems[0])
            alert('Server börjar ladda en ny DATA fil, detta tar en stund men den bör uppdateras inom kort!')
        }
    }, [selectedMenuItem])

    return (
        <ProductsContext.Provider value={{
            menuItems,
            products,
            selectedMenuItem,
            setSelectedMenuItem
        }}>
            <Style className="layout-container">


                {!mobile && <Sidebar />}


                {selectedMenuItem.type == 'category' && <ProductsCard products={products.filter(p => {
                    if (selectedMenuItem.key == 'all') return true;

                    if (selectedMenuItem.key == 'red') {
                        if (p.segment.toUpperCase().includes('RÖ')) return true;
                    }

                    if (selectedMenuItem.key == 'white') {
                        if (p.segment.toUpperCase().includes('VITT')) return true;
                    }

                    if (selectedMenuItem.key == 'rose') {
                        if (p.segment.toUpperCase().includes('ROSÉ')) return true;
                    }

                    if (selectedMenuItem.key == 'box') {
                        if (p.segment.toUpperCase().includes('BOX')) return true;
                    }

                    return false;
                })}/>}

                {selectedMenuItem.key == 'users' && <Users />}
                {selectedMenuItem.key == 'sb_images' && <SBImages />}


            </Style>
        </ProductsContext.Provider>
    )
}


const Style = styled.main`
    margin-top: 20rem;
    margin-bottom: 20rem;
`