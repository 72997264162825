import React, { createContext, useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import {  useHistory } from 'react-router-dom';
import styled from 'styled-components'
import TuesdayWine from '../util/TuesdayWine';
import {default as NumberComponent} from '../components/Number';
import LoaderContext from '../context/LoaderContext';

// ProductsContext holds data for child components.
export const ProductsContext = createContext();

export default function ProductStock() {

    const history = useHistory()

    const {setLoader} = useContext(LoaderContext);

    // Local state for which column to sort by.
    const [sorting, setSorting] = useState({key: 'estimatedDaysLeftInStock', order: 'asc'});
    
    // Store loaded list of products.
    const [products, setProducts] = useState([]);

    // Clone products to sortable array.
    let sortedProducts = [...products];

    // If sorting is active, run the sorting function.
    if (sorting.key != 'index') {
        sortedProducts.sort((a, b) => {
            let {key, order} = sorting;

            let av = eval(`a${key.split('.').map(s => `['${s}']`).join('')}`);
            let bv = eval(`b${key.split('.').map(s => `['${s}']`).join('')}`);

            if (key == 'weeksPlanning') {
                av = a.summary.weeksPlanning;
                bv = b.summary.weeksPlanning;

                if (av == -1) av = 30;
                if (bv == -1) bv = 30;
            }

            if (!isNaN(av)) av = Number(av)
            if (!isNaN(bv)) bv = Number(bv)

            if (order == 'asc') {
                if (av > bv) return 1;
                if (av < bv) return -1;
                return 0;
            } else {
                if (av > bv) return -1;
                if (av < bv) return 1;
                return 0;
            }
        }).sort((a, b) => {
            if (a.averageSales == 0) return 1;
            if (b.averageSales == 0) return -1;
            return 0;
        });
    }
    
    useEffect(() => {
        setLoader({visible: true, message: `Laddar produkter...`});

        TuesdayWine.get(`/products/stock`).then(res => {
            setProducts(res)
            setLoader({visible: false})
        }).catch(err => {
            setLoader({visible: false})
            console.error(err)
        });
    }, [])

    // Renders a Table Cell with a formatted number value.
    const ColumnNumber = ({children, unit = ''}) => (
        <td>
            <span className="number">
                <NumberComponent>{Math.round(Number(children))}</NumberComponent>
            </span>
            {unit && 
                <span className="unit">{` ${unit}`}</span>
            }
        </td>
    )

    // Renders an interactive heading for the column, when pressed the sorting will update.
    const ColumnHeader = ({children, sortingKey, defaultOrder = 'desc'}) => (
        <th onClick={() => {
            if (sortingKey == sorting.key) setSorting({key: sortingKey, order: sorting.order == 'asc' ? 'desc' : 'asc'})
            else setSorting({key: sortingKey, order: defaultOrder})
        }}>
            {children}
            {sortingKey && <span className={`sort-icon ${sortingKey == sorting.key ? (sorting.order == 'asc' ? 'up' : 'down') : ''}`} />}
        </th>
    )

    return (
        <Style className="layout-container">
            <TableStyle cellSpacing={0} className="wide">
            
                <thead>
                    <tr>
                        <ColumnHeader sortingKey="estimatedDaysLeftInStock">Days</ColumnHeader>
                        <ColumnHeader sortingKey="name" defaultOrder="asc">Product</ColumnHeader>
                        <ColumnHeader sortingKey="art_nr_full">Product no</ColumnHeader>
                        <ColumnHeader sortingKey="todaySales">Sales Today</ColumnHeader>
                        <ColumnHeader>AVG. Sales</ColumnHeader>
                        <ColumnHeader sortingKey="latestStock">Stock</ColumnHeader>
                    </tr>
                </thead>

                <tbody>
                    {sortedProducts.map(p => {

                        const nextOrderDelivery = p.nextOrder ? new Date(p.nextOrder.eta) : null;
                        const dateNow = new Date();
                        const dateEstimatedOutOfStock = new Date(dateNow).addDays(p.estimatedDaysLeftInStock);
                        const willBeOutOfStock = nextOrderDelivery && dateEstimatedOutOfStock.isBefore(nextOrderDelivery);

                        return (
                            (
                                <tr key={p.art_nr} onClick={() => {
                                    history.push(`/${p.art_nr_full}`)
                                }}>
                                    <ColumnNumber>{p.estimatedDaysLeftInStock}</ColumnNumber>
                                    <td>
                                        <div className="product-info">
                                            <h3 className="color-orange" style={{paddingBottom: 0}}>{p.name}</h3>
                                            <p style={{paddingBottom: '1em', color: willBeOutOfStock ? 'red' : 'white'}}>{!p.nextOrder ? '' : `${p.nextOrder.amountPerUnit} st  |  ${p.nextOrder.eta}`}</p>
                                        </div>
                                    </td>
                                    <td><p className="color-white number">{p.art_nr_full}</p></td>
                                    <ColumnNumber unit="PCS">{p.todaySales}</ColumnNumber>
                                    <ColumnNumber unit="PCS">{p.averageSales}</ColumnNumber>
                                    <ColumnNumber unit="PCS">{p.latestStock}</ColumnNumber>
                                </tr>
                            )
                        )
                    })}
                </tbody>
                    
            </TableStyle>
        </Style>
    )
}


const Style = styled.main`
    margin-top: 20rem;
    margin-bottom: 20rem;
    padding-left: 20rem;
    padding-right: 20rem;

    overflow-x: auto;
`


const TableStyle = styled.table`
    grid-column: span 12;
    min-width: 1200px;

    .sort-icon {
        background: var(--color-blue-gray);
        font-size: 10px;
        width: 1em;
        height: 1em;
        margin-left: 5px;
        display: inline-block;

        clip-path: polygon(
            0.00em 0.45em,
            1.00em 0.45em,
            1.00em 0.55em,
            0.00em 0.55em
        );

        &.up {
            clip-path: polygon(
                0.00em 0.70em,
                1.00em 0.70em,
                0.50em 0.20em
            );
        }

        &.down {
            clip-path: polygon(
                0.00em 0.30em,
                1.00em 0.30em,
                0.50em 0.80em
            );
        }

    }

    thead tr th {
        color: var(--color-blue-gray);
        text-transform: uppercase;
        padding-bottom: 10px;
        cursor: pointer;
        user-select: none;

        h3 {
            display: inline-block;
        }
    }

    th, td {
        text-align: right;
    }
    th:nth-child(2), td:nth-child(2),
    th:nth-child(1), td:nth-child(1) {
        text-align: left;
    }

    th:nth-child(1), td:nth-child(1) {
        width: 80px;
    }


    tbody {
        tr {
            cursor: pointer;
            td {
                border-bottom: 2px solid var(--color-gray);

                // Product Name
                h3 {
                    padding: 23px 0;
                }
            }
        }
    }


    .mobile & {
        min-width: 600px;
    
        * {
            font-size: 12px !important;
        }

        thead tr th {
            font-size: 10px !important;
        }

        th:nth-child(1), td:nth-child(1) {
            width: 60px;
        }

        th:nth-child(2), td:nth-child(2) {
            width: 150px;

            h3 {
                padding-top: 10px;
                max-width: 150px;
                white-space: nowrap;
                // elipsis
                overflow: hidden;
                text-overflow: ellipsis;

            }
        }
        
    }


`