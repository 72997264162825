import { useEffect, useState } from "react";

/**
 * Behaves just like a state but will keep the data stored in session storage.
 * 
 * @param {string} key The local storage key.
 * @param {object} defaultValue Initial value.
 */
export default function useSessionState(key, defaultValue) {

    // Load data from session storage.
    let _default = typeof sessionStorage == 'object' && JSON.parse(sessionStorage.getItem(key)) || defaultValue;

    // Internal state.
    const [state, setState] = useState(_default);

    // Update local storage on state updates.
    useEffect(() => {
        if (typeof sessionStorage != 'object') return;

        // Remove if undefined.
        if (state == undefined) return sessionStorage.removeItem(key)

        let storedValue = sessionStorage.getItem(key)
        let stringified = JSON.stringify(state)

        // Check if changed from default.
        if (storedValue == stringified) return;

        // Update storage.
        sessionStorage.setItem(key, stringified)
    
    }, [state]);
    

    return [state, setState]
}