import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import Arrow from '../../components/Arrow';
import LoaderContext from '../../context/LoaderContext';
import TuesdayWine from '../../util/TuesdayWine';
import { useDevices, useIsTV } from '../../util/Util';
import TVProduct from '../TVProduct/TVProduct';
import ProductView from './ProductView'
import Sidebar from './Sidebar';

export const ProductContext = createContext();

export default function Product({match}) {

    const history = useHistory();

    const isTV = useIsTV();

    // Get URL params.
    let {productNumber, packageNumber} = match.params;

    const [data, setData] = useState();
    const {setLoader} = useContext(LoaderContext);

    const [view, setView] = useState('all')
    const [open, setOpen] = useState(false)

    const [mobile, tablet, desktop] = useDevices()

    // Hook for page change.
    useEffect(() => {
        window.scrollTo({top: 0});
        

        let load = async () => {
            console.log(`Fetching data at ${new Date().getTimestamp()}`)
            setData(await TuesdayWine.getSummary(packageNumber ? `${productNumber}/${packageNumber}` : productNumber))
            setLoader((loader) => ({...loader, visible: false}));
        }


        try {
            let user = jwtDecode(localStorage.getItem('user'));
            
            if (user.role != 1 && user.role != 2)
                if (!TuesdayWine.getAllowedProducts().includes(productNumber)) history.push('/')
        } catch(e) {
            history.push('/')
        }

        setLoader({visible: true, message: `Förbereder produkt: ${productNumber}/${packageNumber}`});
        load();

        
        // Run every 30 minutes
        let interval = setInterval(load, 30 * 60 * 1000)

        return () => {
            clearInterval(interval);
        }
    }, [productNumber, packageNumber])



    return (
        <ProductContext.Provider value={{
            view, setView, open, setOpen, data
        }}>
            
            {isTV && <TVProduct data={data} />}
            
            {!isTV && <Style className={`layout-container`}>

                {mobile && <Header>

                    <div className="back" onClick={() => history.push('/')}>
                        <Arrow left/>
                        <h3>All Products</h3>
                    </div>


                    <div className={`menubutton ${open ? 'close' : ''}`} onClick={() => setOpen(!open)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>


                </Header>}


                <Sidebar />
                <ProductView data={data}/>
            </Style>}
        </ProductContext.Provider>
    )
}

const Header = styled.div`
    background: var(--color-background);
    border-bottom: var(--color-gray) 1px solid;
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
    height: 70rem;

    padding: 10rem;

    display: flex;
    align-items: center;

    .back {
        display: flex;
        align-items: center;
        color: var(--color-blue-gray);

        .arrow {
            font-size: 30px;
            margin-right: 10px;
        }
    }

    .menubutton {
        font-size: 40px;

        margin-left: auto;

        width: 1em;
        height: 1em;


        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;

        div {
            height: 2px;
            background: white;
            transition: 
                width 300ms,
                transform 300ms
        }

        div:nth-child(1) {
            width: 100%;
        }

        div:nth-child(2) {
            width: 80%;
        }

        div:nth-child(3) {
            width: 90%;
        }

        &.close {
            div:nth-child(1) {
                width: 75%;
                transform: translateX(-17.5%) translateY(0.33em) rotate(-45deg);
            }
    
            div:nth-child(2) {
                width: 0%;
            }
    
            div:nth-child(3) {
                width: 75%;
                transform: translateX(-17.5%) translateY(-0.33em) rotate(45deg);
            }
        }
    }
`

const Style = styled.main`
    margin-top: 20rem;
    margin-bottom: 20rem;

    .mobile & {
        margin-top: 90rem;
    }
`