import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import AdminOrderRow from '../../components/AdminOrderRow';
import LoaderContext from '../../context/LoaderContext';
import TuesdayWine from '../../util/TuesdayWine';

export default function SBImages() {
    
    const {loader, setLoader, loggedIn} = useContext(LoaderContext);
    const history = useHistory();

    const [products, setProducts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setProducts(await TuesdayWine.get('/image-checker'))
            setLoader(false)
        })()
    }, [])

    const check = async () => {
        setLoading(true)
        try {
            await TuesdayWine.post('/image-checker/run-check')
        } catch(e) {

        }
        setTimeout(async () => {
            setProducts(await TuesdayWine.get('/image-checker'))
            setLoading(false)
        }, 1000 * 5)
    }

    const add = async () => {
        let id = prompt('Product ID:')
        let success = await TuesdayWine.post(`/image-checker/${id}`)
        if (success == false) {
            alert('The product could not be added, probably due to it already existing!')
        }
        setProducts(ps => [...ps, {
            product_id: id,
            added: new Date().toJSON()
        }])
    }



    if (!products) return <></>
    return (
        <Style className={`card`}>

            <div className="row">
                <h2 className="color-blue-gray">SB Images</h2>
                <div className="spacer auto"></div>
                <button onClick={check} className={loading && 'loading'}>Manually Check Images</button>
                <div className="spacer wxs"></div>
                <button onClick={add}>Add Product</button>
            </div>

            <div className="spacer m"></div>

            <table>
                <thead>
                    <tr>
                        <th>Product Number</th>
                        <th>Added</th>
                        <th>Last changed</th>
                        <th>Previous Image</th>
                        <th>Current Image</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {products.map(p => (
                        <tr key={JSON.stringify(p)}>
                            <td>{p.product_id}</td>
                            <td>{p.added ? new Date(p.added).toJSON().substring(0, 16).replace('T', ' ') : ' - '}</td>
                            <td>{p.last_change ? new Date(p.last_change).toJSON().substring(0, 16).replace('T', ' ') : ' - '}</td>
                            <td>{p.last_image ? <img src={p.last_image} width={50} /> : <> - </>}</td>
                            <td>{p.current_image ? <img src={p.current_image} width={50} /> : <> - </>}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </Style>
    )
}

const Style = styled.main`


    padding: 15rem;

    grid-column: span 10;


    table {
        th {
            text-align: left;
        }

        tr {
            td {

                &.title-row {
                    max-width: 80rem;
                    font-size: 10rem;
                    padding-right: 20rem;
                    font-weight: normal;
                }

                height: 70px;

            }
        }
    }


`