import React from 'react';
import Number from "./Number";



export default function KeyValueGroup({label, primaryValue, secondaryValue, primaryUnit = 'pcs', secondaryUnit = 'sek'}) {

    return <div className="key-value-group">
        {label && <p className="color-gray">{label}</p>}
        {primaryValue && <h2><Number>{Math.round(primaryValue)}</Number><span className="unit"> {primaryUnit}</span></h2>}
        {secondaryValue && <div className="number"><Number>{Math.round(secondaryValue)}</Number><span className="unit"> {secondaryUnit}</span></div>}
    </div>
}
