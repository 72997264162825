import React, { useContext } from 'react'
import styled from 'styled-components'
import SalesCard from '../Product/SalesCard'
import PrognosisCard from '../Product/PrognosisCard'
import StockCard from '../Product/StockCard'
import defaultWine from '../../assets/images/default_wine.png'

export default function TVProduct({data, art_nr}) {
    
    return (
        <Style>

            <div className="product-info">
                <img src={data?.productInfo?.thumbnail ? data?.productInfo?.thumbnail : defaultWine} />
                <div className="column">
                    <h1>{data?.productInfo?.name}</h1>
                    <h3 className="color-blue-gray">{art_nr ?? data?.productInfo?.art_nr_full}</h3>
                </div>
            </div>

            <SalesCard data={data} />
            <PrognosisCard data={data} />
            <StockCard data={data} />
        </Style>
    )
}

const Style = styled.main`

    padding: 20rem;

    display: grid;
    gap: 20rem;
    grid-wrap: wrap;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    max-height: 100vh;


    .product-info {
        grid-column: 10 / 13;
        grid-row: 1 / 3;

        flex: 1;
        display: flex;
        align-items: center;
        // margin-left: 20px;

        img {
            width: 100rem;
            height: 80rem;
            object-fit: contain;
        }


        .column {
            display: flex;
            flex-direction: column;

            h1 {
                line-height: 20rem;
                font-size: 20rem;
            }

            h3 {
                font-size: 15rem !important;
            }
        }
    }

    .sales-card {
        grid-column: 1 / 10;
        grid-row: 1 / 6;
    }

    .prognosis-card {
        grid-column: 1 / 10;
        grid-row: 6 / 13;
    }

    .stock-card {
        grid-column: 10 / 13;
        grid-row: 3 / 13;

        .group-grid > * {
            width: 100%;
        }
    }
`