import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';


let tid = undefined;

export default function Login() {
    


    const {loader, setLoader, setLoggedIn} = useContext(LoaderContext);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [status, setStatus] = useState('');

    const [loading, setLoading] = useState(false)
    
    useEffect(() => {

        (async () => {
            const params = window.location.search.replace('?', '').split('&').map(s => s.split('='));
    
            let get_user = params.find(p => p[0] == 'u');
            let get_pass = params.find(p => p[0] == 'p');
    
            if (get_user && get_pass) {
                console.log(get_user)
                console.log(get_pass)
        
                let token = await TuesdayWine.authenticate(get_user[1], get_pass[1], true);
                setLoading(false)
    
                if (token.success === false) {
                    if (token.reason == 'Invalid password') setStatus('Invalid password')
                    setStatus(token.reason);
                    return;
                }
        
                // Set the user in localstorage.
                localStorage.setItem('user', token);
                setLoggedIn(token);
        
            } else {
                setLoader(false)
            }
    
    
    
        })()

    }, [])


    useEffect(() => {
        clearTimeout(tid);
        tid = setTimeout(() => {
            setStatus('');
        }, 4000)
    }, [status])



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true)

        let token = await TuesdayWine.authenticate(email, password);
        setLoading(false)

        if (token.success === false) {
            if (token.reason == 'Invalid password') setStatus('Invalid password')

            setStatus(token.reason);
            return;
        }

        // Set the user in localstorage.
        localStorage.setItem('user', token);
        setLoggedIn(token);

    }

    return (
        <Style>
            
            <form className="card" onSubmit={submit}>
                <h2>Login</h2>

                <div className="spacer s"></div>

                <input required type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
                <input required  type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>

                <div className="spacer xs"></div>
                <input disabled={loading} type="submit" value="Login"/>

                <p className="status">{status}</p>

            </form>

        </Style>
    )
}

const Style = styled.main`
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    form {
       padding: 1em; 

       .status {
           font-size: 0.6em;
           text-align: center;
           margin-top: 0.6em;
       }

    }


`
