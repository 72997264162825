import React, { useState } from 'react'
import styled from 'styled-components'

export default function Table({data, structure, limit = 10, onClick = () => {}}) {

    const firstSortableColumn = structure.find(c => c.sortable);

    const [sorting, setSorting] = useState(firstSortableColumn ? {
        column: structure.indexOf(firstSortableColumn),
        order: 'desc'
    } : undefined)
    const [page, setPage] = useState(0)

    const [filters, setFilters] = useState({});

    

    const sort = (a, b) => {
        if (!sorting) return;
        let av = a[structure[sorting.column].key];
        let bv = b[structure[sorting.column].key];

        if (!isNaN(av)) {
            if (sorting.order == 'desc') return bv - av
            else return av - bv
        } else {
            if (sorting.order == 'desc') return ('' + bv).localeCompare(av)
            else return ('' + av).localeCompare(bv)
            
        }
    }

    const filter = (row, index) => {

        if (Object.keys(filters).length > 0) {

            for (let filter of Object.keys(filters)) {
                let value = filters[filter];
                // if (row[filter] == undefined) break;


                if (typeof value == 'string') {
                    if (!String(row[filter]).toLowerCase().includes(String(value).toLowerCase())) return false;
                }


                if (typeof value == 'object') {
                    if (value.type == 'numbers') {
                        if (value.from) {
                            if (row[filter] < Number(value.from)) return false;
                        }

                        if (value.to) {
                            if (row[filter] > Number(value.to)) return false;
                        }
                    }

                    if (value.type == 'date') {
                        if (value.from) {
                            if (new Date(row[filter]).getTime() < new Date(value.from).getTime()) return false;
                        }

                        if (value.to) {
                            if (new Date(row[filter]).getTime() > new Date(value.to).getTime()) return false;
                        }
                    }
                }


            }
        }

        return true;
    }

    const paginate = (row, index) => {
        if (index < (page + 1) * limit && index >= (page) * limit) return true;
    }

    const next = () => {
        if (page >= pages - 1) return;
        setPage(page + 1);
    }

    const prev = () => {
        if (page == 0) return;
        setPage(page - 1);
    }

    const updateSorting = (index) => {
        if (sorting && sorting.column == index) {
            let s = {...sorting}
            if (s.order == 'asc') s.order = 'desc';
            else s.order = 'asc';
            setSorting(s)
        } else {
            setSorting({
                column: index,
                order: 'asc'
            })
        }
        setPage(0);
    }


    let filteredData = data ? data.sort(sort).filter(filter) : [];
    let pages = Math.round(filteredData.length / limit);

    return (
        <TableStyle>

            
            <table cellSpacing={0}>
                <thead>
                    <tr>
                        {structure && structure.map((column, index) => {
                            return (
                                <th 
                                    style={column.cellStyle} 
                                    key={index}
                                    className={`${column.sortable ? 'sortable' : ''} ${sorting && sorting.column == index ? sorting.order : ''}`}
                                >
                                    <div onClick={() => column.sortable && updateSorting(index)}>{column.heading}</div>

                                    {column.filter == 'text' && (<>
                                        <br />
                                        <span className="text-filter filter">
                                            <input className="text-filter" type="text" onChange={e => setFilters({...filters, [column.key]: e.target.value})}/>
                                        </span>
                                    </>)}

                                    {column.filter == 'numbers' && (<>
                                        <br />
                                        <span className="numbers-filter filter">
                                            <input className="numer-filter" type="number" onChange={e => setFilters({...filters, [column.key]: {type: 'numbers', ...filters[column.key], from: e.target.value}})}/>
                                            -
                                            <input className="numer-filter" type="number" onChange={e => setFilters({...filters, [column.key]: {type: 'numbers', ...filters[column.key], to: e.target.value}})}/>
                                        </span>
                                    </>)}



                                    {column.filter == 'date' && (<>
                                        <br />
                                        <span className="numbers-filter filter">
                                            <input className="date-filter" type="date" onChange={e => setFilters({...filters, [column.key]: {type: 'date', ...filters[column.key], from: e.target.value}})}/>
                                            -
                                            <input className="date-filter" type="date" onChange={e => setFilters({...filters, [column.key]: {type: 'date', ...filters[column.key], to: e.target.value}})}/>
                                        </span>
                                    </>)}
                                
                                </th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>
                    {filteredData.filter(paginate).map((row, rowIndex) => {
                        if (row)
                        return (
                            <tr key={rowIndex} onClick={() => onClick(row)}>
                                {structure && structure.map((column, index) => {
                                    return <td style={column.cellStyle} key={index}>{(() => {
                                        if (column.component) return column.component(row, rowIndex);
                                        if (column.key) return row[column.key];
                                    })()}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>

            {
                pages != 0 &&
                <div className="pagination">
                    <div className="prev" onClick={() => prev()}></div>
                    {page + 1} / {pages == 0 ? 1 : pages}
                    <div className="next" onClick={() => next()}></div>
                </div>
            }
            
        </TableStyle>
    )
}

const TableStyle = styled.div`

    display: flex;
    flex-direction: column;

    .pagination {
        display: flex;
        align-items: center;
        align-self: flex-end;
        margin-top: 2em;
        margin-right: 1em;

        div {
            cursor: pointer;
            font-size: 0.7em;
            margin: 0.5em;
            width: 1em;
            height: 1em;
            border-bottom: solid 0.1rem black;
            border-right: solid 0.1rem black;
            transform: rotate(135deg);

            &.next {
                transform: rotate(-45deg);
            }
        }
    }



    table {
        
        width: 100%;  
        
        
        
        td, th {
            text-align: left;
            padding: 0.75em;
            border-bottom: solid 0.5em transparent;
            border-top: solid 0.5em transparent;

            div {
                display: inline;
            }

            .filter {
                padding-top: 0.5em;
            }

            position: relative;

            &:before {
                content: '';
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                bottom: -0.5em;
                left: 0;
                width: 100%;
                height: 1px;
            }

        }

        tbody {
            // tr {
            //     cursor: pointer;
            //     outline: none;
            //     transition: background 50ms;
            //     &:hover, &:focus-within {
            //         background: #323232;
            //     }
            // }
        }
    }


    .sortable {

        
        div {
            cursor: pointer;
            padding-right: 2em;
            position: relative;
            user-select: none;
        }

        &.asc div:after, &.desc div:after {
            content: '';
            position: absolute;
            display: block;
            width: .5em;
            height: .5em;
            top: 50%;
            right: 0.75em;
            transform: translate(0, -50%) rotate(45deg) translateY(-50%);
            border-style: solid;
            border-color: #b1b1b1;
            border-width: 0em;
            border-bottom-width: 0.1em;
            border-right-width: 0.1em;
        }

        &.asc div:after {
            transform: translate(70%, -50%) rotate(-135deg) translateY(-50%);
        }


        &:not(.asc):not(.desc) div:after {
        // &:before {
            content: '';
            position: absolute;
            display: block;
            width: 0.8em;
            height: .15em;
            top: 50%;
            right: 0.75em;
            transform: translate(40%, -50%);
            background: #b1b1b1;
        }

        

    }


`
