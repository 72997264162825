import React from 'react'
import styled from 'styled-components'

export default function Icon({children, className, onClick = () => {}, disabled}) {
    return (
        <Style onClick={(e) => {
            if (!disabled) onClick(e)
        }} className={`material-icons ${className ? className : ''} ${disabled ? 'disabled' : ''}`}>
            {children}
        </Style>
    )
}


const Style = styled.i`
    cursor: pointer;
    user-select: none;
    color: #777;
    font-size: inherit;
    display: inline;
    font-size: 1.5em;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`