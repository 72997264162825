import React from 'react'
import styled from 'styled-components'
import { useDevices } from '../../util/Util'
import ProductsTable from './ProductsTable'

export default function ProductsCard({products}) {

    const [mobile, tablet, desktop] = useDevices();

    return (
        <Style className={`card`}>
            {!mobile && <>
                <h2 className="color-blue-gray">Showing "All Products"</h2>
                <div className="spacer m"></div>
            </>}
            <ProductsTable products={products}/>
        </Style>
    )
}

const Style = styled.div`
    grid-column: auto;
    padding: 15rem;

    grid-column: span 10;


    .tablet & {
        grid-column: span 6;
    }

    .mobile & {
        grid-column: span 2;
        background: none;
        border: none;
    }

`