import React from 'react'
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import styled from 'styled-components';
import { useDevices, useIsTV, useWindowSize } from '../../util/Util';


const renderLegend = (props) => {
    const { payload } = props;
  
    return (
        <div style={{
            display: 'flex', 
            justifyContent: 'space-evenly'
        }}>
            {payload.map(({ color, value }, index) => {
                return <div key={value} style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{
                        width: '10px',
                        height: '10px',
                        background: color,
                        display: 'block',
                        marginRight: '5px'
                    }}/>
                    <span style={{ color: '#868686', fontSize: '0.8em' }}>{value}</span>
                </div>;
            })}
        </div>
    );
}



export default function PrognosisGraph({data}) {


    let graphData = (data && data.forecastGraph) ? data.forecastGraph : [];


	const [mobile, tablet, desktop] = useDevices();
	const [width, height] = useWindowSize()
    const isTV = useIsTV();

	graphData = graphData.map(d => {

		return {
			...d,
			predictedStock: d.predictedStock < 0 ? 0 : d.predictedStock,
		}
	})

	
	if (graphData && mobile) {
		graphData = graphData.filter((d,i) => i < 4*3);
	} else {
		graphData = graphData.filter((d,i) => i < 4*6);
    }


    return (
        <Style>
            <ResponsiveContainer width="100%" height={isTV ? (300) : (!mobile ? 230 : 180)}>
                <ComposedChart
                    data={graphData}
                    margin={{ top: 50, right: 0, bottom: 0, left: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#7D8DCB" stopOpacity={0.6}/>
                            <stop offset="75%" stopColor="#4D6BE2" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis interval={0} dataKey='week'/>
                    <YAxis hide={true} />
                    
                    {true && <Tooltip
                        formatter={function(value, name) {
                            return `${Math.round(value)}`;
                        }}
                        labelFormatter={function(value) {
                            return `Vecka ${value}`;
                        }}
                        contentStyle={{
                            border: '1px solid rgba(96, 96, 96, 0.47)'
                        }}
                    />}

                    <Legend content={renderLegend} />
                    <Area type='monotone' name="Predicted" dataKey='predictedStock' strokeWidth="0.1em"  stroke='#5E6999' fillOpacity={1} fill="url(#colorUv)" />
                    
                    <Line
                        type='monotone'
                        name="Safety Stock"
                        dataKey='safetyStock'
                        stroke='#FF7750'
                        dot={false}
                    />

                    <Bar 
                        dataKey='weekForecast' 
                        name="Forecast" 
                        barSize={4} 
                        radius={[10, 10, 0, 0]}
                        fill='var(--color-blue-gray)' 
                    />
                    
                    <Bar 
                        dataKey='weekIncommingTotal' 
                        name="Incoming" 
                        barSize={4} 
                        radius={[10, 10, 0, 0]}
                        fill='var(--color-green)'
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Style>
    )
}

const Style = styled.div`

	width: 100%;


	.recharts-legend-item {
		svg {
			display: none !important;
		}
	}

	.recharts-text.recharts-cartesian-axis-tick-value {
		// fill: white !important;
		font-size: 0.7em;
	}

	.recharts-area {
		path.recharts-curve recharts-area-curve {
			stroke: #5E6999 !important;
		}
	}
`