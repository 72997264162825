import React, { useContext } from 'react'
import styled from 'styled-components'
import Arrow from '../../components/Arrow'
import { ProductContext } from './Product'
import PrognosisCard from './PrognosisCard'
import SalesCard from './SalesCard'
import StockCard from './StockCard'
import defaultWine from '../../assets/images/default_wine.png'

export default function ProductView({data}) {

    const {view} = useContext(ProductContext)

    return (
        <Style className={`card`}>

            <Header>

                {/* <Arrow left /> */}
                
                <div className="product-info">
                    <img src={data?.productInfo?.thumbnail ? data?.productInfo?.thumbnail : defaultWine} />
                    <div className="column">
                        <h1>{data?.productInfo?.name}</h1>
                        <h3 className="color-blue-gray">{data?.productInfo?.art_nr_full}</h3>
                    </div>
                </div>

                {/* <Arrow right /> */}
                
            </Header>

            {(view == 'all' || view == 'sales') && <>
                <div className="spacer m" />
                <SalesCard data={data} />
            </>}

            {(view == 'all' || view == 'prognosis') && <>
                <div className="spacer m" />
                <PrognosisCard data={data} />
            </>}

            {(view == 'all' || view == 'stock') && <>
                <div className="spacer m" />
                <StockCard data={data} />
            </>}

        </Style>
    )
}

const Header = styled.div`

    display: flex;
    align-items: center;

    .product-info {
        flex: 1;
        display: flex;
        align-items: center;
        // margin-left: 20px;

        img {
            width: 130px;
            height: 130px;
            object-fit: contain;
        }


        .column {
            display: flex;
            flex-direction: column;

            h2 {
                margin-bottom: 10px;
            }
        }
    }

    .mobile & {
        .product-info {
            img {
                width: 80px;
                height: 80px;
            }
        }
    }

`

const Style = styled.div`

    padding: 50px; 
    grid-column: span 10;


    .tablet & {
        padding: 20px; 
        grid-column: span 6;
    }

    .mobile & {
        background: none !important;
        border: none !important;
        padding: 0px; 
        grid-column: span 2;
    }
`