import React from 'react'
import styled from 'styled-components'
import arrow from '../assets/images/arrow.svg'

export default function Arrow({left, right, onClick}) {

    let direction = 'right';

    if (left) direction = 'left';
    if (right) direction = 'right';

    return (
        <Style 
            className={`arrow ${direction}`} 
            src={arrow} 
            onClick={onClick}
        />
    )
}

const Style = styled.img`
    font-size: 40px;

    cursor: pointer;
    user-select: none;

    background: linear-gradient(91.19deg, rgba(89, 89, 89, 0.33) 0%, rgba(55, 55, 55, 0.33) 98.98%);
    border-radius: 100vw;
    width: 1em;
    height: 1em;
    padding: 0.2em;

    &.left {
        transform: rotate(180deg);
    }
`