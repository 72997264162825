import React, { useState } from 'react'
import TuesdayWine from '../util/TuesdayWine';

export default function AdminOrderRow({ order, onChange }) {

    const [changes, setChanges] = useState({})
    const [loading, setLoading] = useState(false)


    const updateOrder = async () => {
        
        let filteredChanges = Object.entries(changes).filter(([k,v]) => v != undefined);

        if (filteredChanges.length != 0) {
            setLoading(true)

            let data = Object.fromEntries(filteredChanges.map(([k,v]) => [k,v == '' ? undefined : v]))

            let newOrder = await TuesdayWine.put(`/orders/${order.id}`, data)
            onChange && onChange(newOrder);
            setChanges({})
            setLoading(false)
        }

    }

    let daysLate = order.jfh_etd ? new Date(order.planned_etd).getDaysBetween(new Date(order.jfh_etd).addDays(1)) : 0;
    let daysEarly = order.jfh_etd ? new Date(order.jfh_etd).addDays(1).getDaysBetween(new Date(order.planned_etd)) : 0;

    return <tr>
        <td>{order.tuesday_order_no}</td>
        <td className="title-row">{order.title}</td>
        <td>{order.globus_order_no}</td>
        <td>
            <input 
                type="text" 
                defaultValue={order.casas_order_no} 
                onChange={e => (e.persist(), setChanges(c => ({
                    ...c, 
                    'casas_order_no': e.target.value == order.casas_order_no ? undefined : e.target.value
                })))}
                className={[
                    changes['casas_order_no'] != undefined ? 'changed' : '',
                    order.casas_order_no == undefined || order.casas_order_no == '' ? 'warning' : '',
                ].join(' ')}
            />
        </td>
        <td>
            <input 
                type="date" 
                defaultValue={order.planned_etd}
                onChange={e => (e.persist(), setChanges(c => ({
                    ...c, 
                    'planned_etd': e.target.value == order.planned_etd ? undefined : e.target.value
                })))}
                className={[
                    changes['planned_etd'] != undefined ? 'changed' : '', 
                    order.planned_etd == undefined ? 'warning' : '',
                    (Math.abs(daysEarly > 7) || Math.abs(daysLate > 7)) ? 'warning-text' : '',
                ].join(' ')}    
            />
        </td>
        <td>
            <input 
                type="text" 
                defaultValue={order.load_from_bordegia} 
                onChange={e => (e.persist(), setChanges(c => ({
                    ...c, 
                    'load_from_bordegia': e.target.value == order.load_from_bordegia ? undefined : e.target.value
                })))}
                className={[
                    changes['load_from_bordegia'] != undefined ? 'changed' : '',
                    order.load_from_bordegia == undefined || order.load_from_bordegia == '' ? 'warning' : '',
                    'loading-week'
                ].join(' ')}

            />
        </td>
        <td>{order.jfh_status || '-'}</td>
        <td>
            {daysEarly == 0 && daysLate == 0 ? '-' : ''}
            {daysLate != 0 ? -daysLate : ''}
            {daysEarly != 0 ? daysEarly : ''}
        </td>
        <td>{order.jfh_etd ? new Date(order.jfh_etd).addDays(1).toJSON().substring(0, 10) : ' - '}</td>
        <td>{order.eta_stockholm ? new Date(order.eta_stockholm).addDays(1).toJSON().substring(0, 10) : ' - '}</td>
        <td><button onClick={() => updateOrder()} className={`${loading ? 'loading' : ''}`}>Update</button></td>
    </tr>
}
