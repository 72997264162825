import { sha256 } from 'js-sha256';
import md5 from "md5";
import jwtDecode from 'jwt-decode';

// const URL = 'http://localhost:4000';
// const URL = 'http://192.168.1.126:5000';
const URL = 'https://server.tuesdaywine.se';
const CACHE_TTL = 60 * 5;
const CACHE_KEY = 'cache_';

export default class TuesdayWine {

    static getURL() {
        return URL;
    }

    static clearCache(ep = undefined) {
        let keys = Object.keys(localStorage).filter(ls => 
            ep == undefined ?
            ls.startsWith(CACHE_KEY) :
            ls.startsWith(CACHE_KEY) && ls.includes(ep)
        );
        keys.map(key => localStorage.removeItem(key));
    }

    static async request(method, ep, body, noCache = false, url = URL) {

        noCache = true;

        let cacheKey = CACHE_KEY + method + '_' + ep + '_' + md5(ep + JSON.stringify(body))
        let result = localStorage.getItem(cacheKey);
        
        if (result && !noCache) {
            result = JSON.parse(result);
            if (result && result.time) {
                if (new Date().getTime() - result.time <= CACHE_TTL * 1000) {
                    return result.result;
                }
            }
        }

        result = await (await fetch(`${url}${ep}`, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('user')}`
            },
        })).json();


        if (result.success == false) return undefined;

        if (!noCache) {
            localStorage.setItem(cacheKey, JSON.stringify({
                result,
                time: new Date().getTime()
            }))
        }

        return result
    }

    static async get(ep, noCache = false) {
        return await TuesdayWine.request('GET', ep, undefined, noCache);
    }

    static async post(ep, data, noCache = false) {
        return await TuesdayWine.request('POST', ep, data, noCache);
    }

    static async put(ep, data, noCache = false) {
        return await TuesdayWine.request('PUT', ep, data, noCache);
    }

    static async delete(ep, data, noCache = false) {
        return await TuesdayWine.request('DELETE', ep, data, noCache);
    }

    static async getSummary(productNumber) {
        return await TuesdayWine.get(`/products/${productNumber}`);
    }

    static getAllowedProducts() {
        let user = jwtDecode(localStorage.getItem('user'));

        // Vinimark
        if (user.role == 3) return [
            '208601',
            '1209408',
            '209908',
            '1209401',
        ]


        // AZ
        if (user.role == 4) return [
            '1234301',
            '1234302',
        ]

        // Jeanneret
        if (user.role == 5) return [
            '659508',
            '647408',
            '240101'
        ]

        // Casas Patronales
        if (user.role == 6) return [
            '689508',
            '213008',
            '689608',
            '208801',
            '6895X8',
            '2130X8',
            '6896X8'
        ]


    }

    static async getProducts(detailed = false) {

        if (!localStorage.getItem('user')) return [];

        let products;
        if (detailed) products = await TuesdayWine.get(`/products/detailed`);
        else products = await TuesdayWine.get(`/products`);

        let user = jwtDecode(localStorage.getItem('user'));
        
        if (user.role != 1 && user.role != 2) {
            products = products.filter(p => this.getAllowedProducts().includes(p.art_nr))
        }

        return products;
    }

    static async getMorningRates() {
        let data = await this.request('GET', `/latest/${new Date().getDateString()}/08:00?symbols=USD,AUD,EUR`, undefined, false, `https://server02.blackpixel.se:1401`);
        
        if (!data) return [];
        if (data.message == 'no data') return [];

        let result = {};

        data.rates.forEach(sym => {
            result[sym.symbol] = sym.rate;    
        });

        return result;    
    }

    static async getRates() {
        let data = await this.request('GET', `/latest/${new Date().getDateString()}?symbols=USD,AUD,EUR`, undefined, false, `https://server02.blackpixel.se:1401`);
        if (!data) return [];
        if (data.message == 'no data') return [];
        
        let result = {};

        data.rates.forEach(sym => {
            result[sym.symbol] = sym.rate;    
        });

        return result;
    }




    static async authenticate(email, password, skipSHA = false) {
        return await (await fetch(`${URL}/authenticate/${email}/${!skipSHA ? sha256(password) : password}`, {
            method: 'GET',
        })).json();
    }

    /**
     * Takes an array of rights and will return true if the user has access.
     */
    static lock(rights) {

        let user = localStorage.getItem('user');
        if (!user) return false;
        
        // Decode the user token.
        user = jwtDecode(user);

        return rights.includes(user.role);
    }




    static async getUsers() {
        return await this.get(`/users`);
    }

    static async getRoles() {
        return await this.get(`/users/roles`);
    }

    static async updateUser(id, data) {
        return await this.put(`/users/${id}`, data);
    }

    static async createUser(data) {
        return await this.post(`/users`, data);
    }

    static async deleteUser(id) {
        return await this.delete(`/users/${id}`);
    }


}