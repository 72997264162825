import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import {default as NumberComponent} from '../../components/Number';
import Arrow from '../../components/Arrow';
import { ProductsContext } from './ProductList'
import {useDevices} from '../../util/Util'

/**
 * Renders a sortable table of products.
 */
export default function ProductsTable({products = []}) {

    // Navigation to product pages.
    const history = useHistory();

    const [mobile, tablet, desktop] = useDevices();
    
    // Local state for which column to sort by.
    const [sorting, setSorting] = useState({key: 'name', order: 'asc'});

    // Clone products to sortable array.
    let sortedProducts = [...products];

    // If sorting is active, run the sorting function.
    if (sorting.key != 'index') {
        sortedProducts.sort((a, b) => {
            let {key, order} = sorting;

            let av = eval(`a${key.split('.').map(s => `['${s}']`).join('')}`);
            let bv = eval(`b${key.split('.').map(s => `['${s}']`).join('')}`);

            if (key == 'weeksPlanning') {
                av = a.summary.weeksPlanning;
                bv = b.summary.weeksPlanning;

                if (av == -1) av = 30;
                if (bv == -1) bv = 30;
            }

            if (!isNaN(av)) av = Number(av)
            if (!isNaN(bv)) bv = Number(bv)

            if (order == 'asc') {
                if (av > bv) return 1;
                if (av < bv) return -1;
                return 0;
            } else {
                if (av > bv) return -1;
                if (av < bv) return 1;
                return 0;
            }
        });
    }

    // Renders a Table Cell with a formatted number value.
    const ColumnNumber = ({children, unit = ''}) => (
        <td>
            <span className="number">
                <NumberComponent>{Math.round(Number(children))}</NumberComponent>
            </span>
            {unit && 
                <span className="unit">{` ${unit}`}</span>
            }
        </td>
    )

    // Renders an interactive heading for the column, when pressed the sorting will update.
    const ColumnHeader = ({children, sortingKey, defaultOrder = 'desc'}) => (
        <th onClick={() => {
            if (sortingKey == sorting.key) setSorting({key: sortingKey, order: sorting.order == 'asc' ? 'desc' : 'asc'})
            else setSorting({key: sortingKey, order: defaultOrder})
        }}>
            {children}
            <span className={`sort-icon ${sortingKey == sorting.key ? (sorting.order == 'asc' ? 'up' : 'down') : ''}`} />
        </th>
    )

    if (mobile) {
        return (
            <MobileStyle>

                <select onChange={(e) => {
                    let parts = e.target.value.split('-');
                    setSorting({
                        key: parts[0],
                        order: parts[1],
                    });
                }}>
                    <option value="name-asc">Name</option>
                    <option value="art_nr_full-asc">Product number rising</option>
                    <option value="art_nr_full-desc">Product number falling</option>
                    <option value="summary.stock.stockAmount-asc">Stock rising</option>
                    <option value="summary.stock.stockAmount-desc">Stock falling</option>
                    <option value="summary.weeksStock-asc">Week stock rising</option>
                    <option value="summary.weeksStock-desc">Week stock falling</option>
                </select>
                
                {sortedProducts.map(p => (
                    <div className="product" key={p.art_nr}>
                        <div className="head" onClick={() => {
                            history.push(`/${p.art_nr_full}`)
                        }}>
                            <h3 data-art={p.art_nr_full} ><Arrow right /> {p.name}</h3>
                        </div>

                        <div className="values">

                            <Value 
                                label="stock"
                                value={p.summary.stock.stockAmount}
                                unit="PCS"
                            />
                            <Value 
                                label="in stock for"
                                value={p.summary.weeksStock}
                                unit="PCS"
                            />

                            <Value 
                                label="sales this month"
                                value={p.summary.soldThisMonth.totalSEK}
                                unit="SEK"
                            />
                            <Value 
                                label="sales today"
                                value={p.summary.soldToday.totalSEK}
                                unit="SEK"
                            />

                            <Value 
                                label="4w progress"
                                value={p.summary.forecast.fourWeeksForecast}
                                unit="PCS"
                            />

                        </div>
                    </div>
                ))}

            </MobileStyle>
        )
    }

    return (
        <TableStyle cellSpacing={0}>
            
            <thead>
                <tr>
                    <ColumnHeader sortingKey="name" defaultOrder="asc"><h3>Product</h3></ColumnHeader>
                    <ColumnHeader sortingKey="art_nr_full">Product no</ColumnHeader>
                    <ColumnHeader sortingKey="summary.stock.stockAmount">Stock</ColumnHeader>
                    <ColumnHeader sortingKey="summary.weeksStock">Week stock</ColumnHeader>
                    <ColumnHeader sortingKey="summary.soldThisMonth.totalSEK">Sales this month</ColumnHeader>
                    <ColumnHeader sortingKey="summary.soldToday.totalSEK">Sales today</ColumnHeader>
                    <ColumnHeader sortingKey="summary.forecast.fourWeeksForecast">4w Prognosis</ColumnHeader>
                </tr>
            </thead>

            <tbody>
                {sortedProducts.map(p => (
                    <tr key={p.art_nr} onClick={() => {
                        history.push(`/${p.art_nr_full}`)
                    }}>
                        <td><h3 className="color-orange">{p.name}</h3></td>
                        <td><p className="color-white number">{p.art_nr_full}</p></td>
                        <ColumnNumber unit="PCS">{p.summary.stock.stockAmount}</ColumnNumber>
                        <ColumnNumber unit="PCS">{p.summary.weeksStock}</ColumnNumber>
                        <ColumnNumber unit="SEK">{p.summary.soldThisMonth.totalSEK}</ColumnNumber>
                        <ColumnNumber unit="SEK">{p.summary.soldToday.totalSEK}</ColumnNumber>
                        <ColumnNumber unit="PCS">{p.summary.forecast.fourWeeksForecast}</ColumnNumber>
                    </tr>
                ))}
            </tbody>
                
        </TableStyle>
    )
}

function Value({value, label, unit = 'SEK'}) {
    return <div className="value">
        <label>{label}</label>
        <h2 className="number"><NumberComponent>{Math.round(value)}</NumberComponent><span className="unit"> {unit}</span></h2>
    </div>
}

const MobileStyle = styled.div`
    .product {
        padding-top: 20rem;
        padding-bottom: 20rem;

        .head {
            h3 {
                .arrow {
                    margin-right: 10rem;
                    font-size: 20rem;
                }

                color: var(--color-orange);
                display: flex;
                align-items: center;
                position: relative;
                padding-bottom: 1em;

                &:after {
                    content: attr(data-art);
                    position: absolute;
                    left: 30rem;
                    top: 1.8em;
                    color: white;
                    font-size: 0.7em;
                }
            }
        }

        .values {

            margin-top: 15rem;
            display: grid;
            gap: 10rem;
            grid-template-columns: 1fr 1fr;

            .value {
                label {
                    color: var(--color-gray);
                    text-transform: uppercase;
                }
            }
        }

        &:not(:last-child), &:first-child {
            border-bottom: solid 2px var(--color-gray);
        }
    }
`

const TableStyle = styled.table`

    .sort-icon {
        background: var(--color-blue-gray);
        font-size: 10px;
        width: 1em;
        height: 1em;
        margin-left: 5px;
        display: inline-block;

        clip-path: polygon(
            0.00em 0.45em,
            1.00em 0.45em,
            1.00em 0.55em,
            0.00em 0.55em
        );

        &.up {
            clip-path: polygon(
                0.00em 0.70em,
                1.00em 0.70em,
                0.50em 0.20em
            );
        }

        &.down {
            clip-path: polygon(
                0.00em 0.30em,
                1.00em 0.30em,
                0.50em 0.80em
            );
        }

    }

    thead tr th {
        color: var(--color-blue-gray);
        text-transform: uppercase;
        padding-bottom: 10px;
        cursor: pointer;
        user-select: none;

        h3 {
            display: inline-block;
        }
    }

    th, td {
        text-align: right;
    }
    th:first-child, td:first-child {
        text-align: left;
    }


    tbody {
        tr {
            cursor: pointer;
            td {
                border-bottom: 2px solid var(--color-gray);

                // Product Name
                h3 {
                    padding: 23px 0;
                }
            }
        }
    }



`