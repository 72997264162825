import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';
import jwtDecode from 'jwt-decode';
import { epochToLocalDateTime } from '../util/Util';
import { useHistory } from 'react-router-dom';
import { MESSAGES } from '../util/SingleAnalyzeMessages';

export default function SingleAnalyzeVinimark({match}) {
    let { id, order_id } = match.params;
    
    const history = useHistory();

    const {setLoader, loggedIn} = useContext(LoaderContext);

    const [analysis, setAnalysis] = useState([])
    const [parameters, setParameters] = useState([])

    const [data, setData] = useState({});

    async function load() {
        setAnalysis((await TuesdayWine.get(`/analyze/${id}`)).analysis)
        setParameters((await TuesdayWine.get(`/analyze/parameters`)).parameters)
        setLoader(false)
    }

    function handleSave(key) {
        return async () => {
            if (!data[key]) return;

            // Validate that required fields are filled in.
            for (let m of parameters) {
                if (m.required && !data[key][m.key]) {
                    return alert(`The parameter '${m.label}' is required`);
                }

                // Validate that there's only digits for each input
                if (m.type !== 'text' && data[key][m.key] !== undefined && data[key][m.key] !== null && !data[key][m.key].toString().match(/^[0-9.,-]+$/)) {
                    return alert(`The parameter '${m.label}' must only contain digits`);
                }
            }

            const resp = await TuesdayWine.put(`/analyze/${id}/${key.replace('step_', '')}`, data[key])
            console.log('resp', resp)
            load();
        }
    }

    function handleDelete(key) {
        return async () => {

            const confirm = window.confirm('Are you sure you want to reset this step?');
            if (!confirm) return;


            const resp = await TuesdayWine.delete(`/analyze/${id}/${key.replace('step_', '')}`)
            setData(d => ({
                ...d,
                [key]: {}
            }))
            console.log('resp', resp)
            load();
        }
    }

    function handleOnPaste(e) {
        const text = e.clipboardData.getData('text/plain');

        // Extracts the rows and columns from the text.
        const rows = text.split('\n').filter(m => m !== '' && m !== "\r" && m !== "\n").map(m => m.split('\t').filter(m => m !== '' && m !== "\r" && m !== "\n").map(v => {
            let val = v.trim();
            if (val[0] === "'") val = val.substring(1);
            return val;
        }));
        
        // Validate that the structure is correct.
        if (rows[0] && rows[0][0] === 'ID' && rows[0][1] === 'Product' && rows[1] && rows[1][0] !== undefined) {
            e.preventDefault();

            setData(d => ({
                ...d,
                step_tuesdaywine: {
                    ...d.step_tuesdaywine,
                    id: rows[1][1 - 1],
                    f_so2: rows[1][3 - 1],
                    t_so2: rows[1][4 - 1],
                    ethanol: rows[1][6 - 1],
                    density: rows[1][5 - 1],
                    glucose: rows[1][7 - 1],
                    fructose: rows[1][8 - 1],
                    glucose_fructose: rows[1][9 - 1],
                    reducing_sugar: rows[1][10 - 1],
                    acidity_8_2: rows[1][11 - 1],
                    acidity_7_0: rows[1][12 - 1],
                    ph: rows[1][11 - 1],
                    tartaric_acid: rows[1][14 - 1],
                    malic_acid: rows[1][13 - 1],
                    lactic_acid: rows[1][14 - 1],
                    citric_acid: rows[1][15 - 1],
                    volatile_acidity: rows[1][16 - 1],
                    glycerol: rows[1][17 - 1],
                    sorbic_acis: rows[1][18 - 1],
                    test_date: rows[1][21 - 1],
                    test_time: rows[1][22 - 1],
                }
            }))

        }
        
    }


    useEffect(() => {
        setLoader(true)
        load();
    }, [])


    if (analysis.length == 0 || parameters.length == 0) return <></>

    const user = jwtDecode(loggedIn);
    const role = user.role;
    if (!user || (role !== 1 && role !== 2 && role !== 3)) {
        return <><h2>Not allowed</h2></>
    }



    const vinimarkEditable = (role === 1 || role === 3);
    const tuesdaywineEditable = (role === 1 || role === 2);


    return (
        <Style className={`layout-container`}>
            <div className="spacer m"></div>

            <div className="analysis card">

                <button className="back-button" onClick={() => history.push(`/analyze/robertson/${order_id}`)}>Back</button>

                <h2>{analysis.wine}</h2>
                <pre className="order-info">
                    <p>Order:         {analysis.order_id}</p>
                    <p>Lot:           {analysis.lot}</p>
                    <p>Art nr:        {analysis.wine_art_nr}</p>
                </pre>
                <div className="spacer s"></div>

                {MESSAGES[analysis.wine_art_nr] && <>
                    <div className="message">
                        {MESSAGES[analysis.wine_art_nr]}
                    </div>
                    <div className="spacer m"></div>
                </>}

                <div className="spacer m"></div>

                <div className="wrapper">
                    <table cellSpacing={5}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Vinimark</th>
                                <th>Tuesday Wine</th>
                                <th>Variation</th>
                            </tr>
                        </thead>
                        <tbody>

                            {parameters.sort((a, b) => {
                                if (a.order > b.order) return 1;
                                if (a.order < b.order) return -1;
                                return 0;
                            }).map(m => {


                                if (m.key === 'aircone') {
                                    return null;
                                }

                                const step_vinimark = data.step_vinimark?.[m.key] ?? (!isNaN(analysis.step_vinimark?.[m.key]?.replace(',', '.')) ? Number(analysis.step_vinimark?.[m.key]?.replace(',', '.')) : undefined);
                                const step_tuesdaywine = data.step_tuesdaywine?.[m.key] ?? (!isNaN(analysis.step_tuesdaywine?.[m.key]?.replace(',', '.')) ? Number(analysis.step_tuesdaywine?.[m.key]?.replace(',', '.')) : undefined);


                                let tuesdaywine_variation = Math.abs(step_vinimark - step_tuesdaywine ?? 0);

                                if (m.type === 'percentage') {
                                    tuesdaywine_variation = Math.abs(tuesdaywine_variation / step_vinimark) * 100;
                                }

                                let vinimark_variation_class = '';
                                let tuesdaywine_variation_class = m.variation !== 0 ? (tuesdaywine_variation > m.variation ? 'warning' : '') : '';

                                if (m.type !== 'text') {

                                    // Validate that there's only digits for each input
                                    if (step_vinimark !== undefined && step_vinimark !== null && !step_vinimark.toString().match(/^[0-9.,-]+$/)) {
                                        vinimark_variation_class = 'warning';
                                    }

                                    if (step_tuesdaywine !== undefined && step_tuesdaywine !== null && !step_tuesdaywine.toString().match(/^[0-9.,-]+$/)) {
                                        tuesdaywine_variation_class = 'warning';
                                    }

                                }

                                // TODO: This is a very, very bad solution. But it works for now.
                                if (m.key === 'f_so2') {

                                    if (step_vinimark <= 35) vinimark_variation_class = 'warning';
                                    if (step_tuesdaywine <= 33) tuesdaywine_variation_class = 'warning';
                                }

                                function setDataParameter(key, value) {

                                    if (value === '') {
                                        setData(d => ({
                                            ...d,
                                            [key]: {
                                                ...d[key],
                                                [m.key]: null
                                            }
                                        }))
                                        return;
                                    }

                                    setData(d => ({
                                        ...d,
                                        [key]: {
                                            ...d[key],
                                            [m.key]: value
                                        }
                                    }))
                                }

                                return (
                                    <tr key={m.key}>
                                        <td>{m.label}</td>

                                        <td>
                                            <input 
                                                type="text"
                                                pattern="[0-9.]{1,5}"
                                                placeholder={m.key === 'f_so2' ? `Min: 35` : ''}
                                                className={vinimark_variation_class + (m.required ? ' required' : '')}
                                                disabled={!vinimarkEditable}
                                                value={(!vinimarkEditable && step_vinimark) ? (Number(step_vinimark)?.toFixed(m.precision ?? 0)) : (step_vinimark ?? '')}
                                                onChange={e => {
                                                    const target = e.target;
                                                    setDataParameter('step_vinimark', target.value)
                                                }}
                                            />
                                        </td>

                                        <td>
                                            <input 
                                                onPaste={handleOnPaste}
                                                type="text"
                                                pattern="[0-9.]{1,5}"
                                                placeholder={m.key === 'f_so2' ? `Min: 33` : ''}
                                                className={tuesdaywine_variation_class + (m.required ? ' required' : '')}
                                                disabled={!tuesdaywineEditable}
                                                value={(!tuesdaywineEditable && step_tuesdaywine) ? (Number(step_tuesdaywine)?.toFixed(m.precision ?? 0)) : (step_tuesdaywine ?? '')}
                                                onChange={e => {
                                                    const target = e.target;
                                                    setDataParameter('step_tuesdaywine', target.value)
                                                }}
                                            />
                                        </td>

                                        <td>
                                            {m.variation !== 0 ? <>
                                                {m.variation.toFixed(m.precision ?? 2)} {m.type === 'percentage' ? '%' : ''}
                                            </> : <></>}
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td></td>
                                <td><button disabled={!vinimarkEditable} onClick={handleSave('step_vinimark')}>Save</button></td>
                                <td><button disabled={!tuesdaywineEditable} onClick={handleSave('step_tuesdaywine')}>Save</button></td>
                            </tr>

                            {role === 1 && <tr>
                                <td></td>
                                <td><button disabled={vinimarkEditable} onClick={handleDelete('step_vinimark')}>Reset</button></td>
                                <td><button disabled={tuesdaywineEditable} onClick={handleDelete('step_tuesdaywine')}>Reset</button></td>
                            </tr>}

                            <tr>
                                <td>Note</td>
                                <td>
                                    <textarea
                                        rows={4}
                                        disabled={!vinimarkEditable}
                                        value={data.step_vinimark?.comment ?? analysis.step_vinimark?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_vinimark: {
                                                    ...d.step_vinimark,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>

                                <td>
                                    <textarea
                                        rows={4}
                                        disabled={!tuesdaywineEditable}
                                        value={data.step_tuesdaywine?.comment ?? analysis.step_tuesdaywine?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_tuesdaywine: {
                                                    ...d.step_tuesdaywine,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>
                            </tr>

                            <tr>
                                <td>Saved</td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_vinimark?.updatedAt)} /></td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_tuesdaywine?.updatedAt)} /></td>
                            </tr>


                        </tbody>
                    </table>
                </div>

                <div className="spacer m"></div>

            </div>
        </Style>
    )
}

const Style = styled.main`

    width: 1000px;

    .analysis {
        grid-column: 1 / 13;

        .back-button {
            display: inline-block;
            width: min-content;
        }


        .order-info {
            p {
                font-size: 16px;
                font-family: monospace;
                font-weight: normal;
                margin: 5rem 0;
            }
        }
        
        .wrapper {
            overflow: auto;
            padding-bottom: 50px;

            table {

                th {
                    text-align: left;
                }

                tr {
                    td {

                        &:first-of-type {
                            font-size: 12px;
                            /* transform: translateY(15px) rotate(-45deg); */
                            text-align: right;
                        }

                        &.title-row {
                            max-width: 80rem;
                            font-size: 10rem;
                            padding-right: 20rem;
                            font-weight: normal;
                        }

                        input {
                            width: 100%;
                            &.warning {
                                color: red;
                            }

                            &.required {
                                border: white solid 1px;
                            }
                        }

                        textarea {
                            width: 100px;
                            resize: none;
                        }
                    }
                }
            }
        }
    }

    .mobile & {
        h2 {
            font-size: 20px;
        }

        table {
            tr {
                td {
                    input, textarea {
                        width: 75px !important;
                    }
                }
            }
        }
    }

`