import jwtDecode from 'jwt-decode'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LoaderContext from '../../context/LoaderContext'
import { ProductsContext } from './ProductList'

export default function Sidebar() {

    const {menuItems, selectedMenuItem, setSelectedMenuItem} = useContext(ProductsContext)
    const {loggedIn} = useContext(LoaderContext);

    return (
        <Style className={``}>
            <h2 className="color-blue-gray">All Products</h2>

            <ul>
                {menuItems.filter(i => i.type == 'category').map(category => (
                    <li 
                        key={category.key} 
                        className={`numbers ${selectedMenuItem.key == category.key ? 'selected' : ''}`}
                        onClick={() => setSelectedMenuItem(category)}
                    >
                        {category.name}
                    </li>
                ))}
            </ul>

            {jwtDecode(loggedIn) && jwtDecode(loggedIn).role == 1 && (<>

                <div className="spacer s"></div>
                <h2 className="color-blue-gray">Admin</h2>

                <ul>
                    {menuItems.filter(i => i.type == 'admin').map(category => (
                        <li 
                            key={category.key} 
                            className={`numbers ${selectedMenuItem.key == category.key ? 'selected' : ''}`}
                            onClick={() => category.disabled == true ? null : setSelectedMenuItem(category)}
                        >
                            {category.name}
                        </li>
                    ))}
                </ul>

            </>)}
        </Style>
    )
}

const Style = styled.div`

    grid-column: span 2;


    .tablet & {
        grid-column: span 6;
        padding: 1.5em;

        ul {
            display: flex;
            padding-left: 0px;

            li {
                padding-bottom: 10px;
                margin-right: 20px;
            }
        }
    }

    .mobile & {
        grid-column: span 2;
    }

    ul {
        list-style: none;
        margin-top: 22px;
        padding-left: 32px;

        li {
            padding-bottom: 10px;
            cursor: pointer;
            user-select: none;

            &.selected {
                color: var(--color-orange);
            }
        }
    }

`