import React from 'react'
import styled from 'styled-components'
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LabelList, Bar, Line, ResponsiveContainer, RadialBarChart, RadialBar, PolarAngleAxis, Text, Label } from 'recharts'
import { useDevices, useIsTV, useWindowSize } from '../../util/Util';

const RadialAppleChart = ({data}) => {

    let s = 100;

    let max = 1;

    let graph = [
        // {
        //   "uv": data.forecast || 0,
        //   "fill": "#FF7750"
        // },
        {
            "uv": data.vismaSalesOfForecast || 0,
            "fill": "#05FFBD"
        },
        {
            "uv": (data.sb_standard / data.avgStandard) || 0,
            "fill": "#D6FF00"
        },
        {
            "uv": (data.sb_premium / data.avgPremium) || 0,
            "fill": "#F83987"
        },
    ]


    return (
        <div className="radial-apple-chart">
            <RadialBarChart 
                width={s} 
                height={s} 
                innerRadius="40%" 
                outerRadius="100%" 
                data={graph} 
                startAngle={90} 
                endAngle={-360 + 90}
            >
                <PolarAngleAxis type="number" domain={[0, max]} dataKey={'pct'} angleAxisId={0} tick={false} />
                <RadialBar label={'displayLabel'} minAngle={15} angleAxisId={0} data={data} background={{ fill: 'rgba(0, 0, 0, 0.2)' }} cornerRadius={20} dataKey='uv' />
                <text
                    x={s / 2}
                    y={s / 2 + 1}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={s * 0.10}
                    fill={'rgba(255, 255, 255, 1)'}
                >
                    {(data.vismaSalesOfForecast * 100).toFixed(0)}%
                </text>

                <text
                    x={s - s * 0.12}
                    y={s - s * 0.10}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={s * 0.10}
                    fill={'rgba(255, 255, 255, 0.5)'}
                >
                    w. {data.week}
                </text>
            </RadialBarChart>
        </div>
    );
}


export default function RadialSalesGraph({data}) {



    
	const [mobile, tablet, desktop] = useDevices();
	const [width, height] = useWindowSize()

    let isPallet = false;

    let graphData = data ? data.salesGraph : [];

	let special = data && data.productInfo?.sum_total == 'true';
	
	graphData = graphData.map(dp => {
		let percentage = special ? (isPallet ? dp.vismaPalletPercentage : dp.vismaBoxPercentage) : 1;

		let visma = (dp.vismaPalletSales + dp.vismaBoxSales) * percentage

		return {
			week: dp.week,
			forecast: dp.forecast,
			visma,
            vismaSalesOfForecast: visma / dp.forecast,
			sb_standard: dp.amount_sold_standard * percentage,
			sb_premium: dp.amount_sold_premium * percentage,
		}
	})


    graphData = graphData.map((dp, index) => {

        let sample = graphData.filter((_,i) => i < index + 4 && i > index);

        let avgStandard = sample.reduce((acc, cur) => acc + cur.sb_standard, 0) / sample.length;
        let avgPremium = sample.reduce((acc, cur) => acc + cur.sb_premium, 0) / sample.length;

        return {
            ...dp,
            avgStandard: isNaN(avgStandard) ? 0 : avgStandard,
            avgPremium: isNaN(avgPremium) ? 0 : avgPremium,
        }
    });


    if (mobile) {
		graphData = graphData?.filter((d,i) => i != 0 && i < 8);
	} else if (tablet) {
        graphData = graphData?.filter((d,i) => i != 0 && i < 12);
	} else {
		graphData = graphData?.filter((d,i) => i != 0 && i < 15);
	}

    return (
        <Style>

            {graphData?.map(data => {
                return <RadialAppleChart
                    key={JSON.stringify(data)}
                    data={data}
                />
            })}

        </Style>
    )
}

const Style = styled.div`

    display: grid; 
    grid-template-columns: repeat(4, 1fr);

    .radial-apple-chart {
        /* border: red dashed 1px; */
    }



    .tablet & {
        grid-template-columns: repeat(3, 1fr);
    }


    .mobile & {
        grid-template-columns: repeat(3, 1fr);
    }

`