import React from 'react'
import Card from '../../components/Card';
import Number from '../../components/Number';

export default function Prognosis({data, isPallet, w, h, scale, mobileOrder}) {

    /**
     * TODO
     * 
     * Vänta på annan data från API, ändra positive eller negative
     */

	let graphData = data ? data.salesGraph : [];
    let special = data && data.productInfo?.sum_total == 'true';

    let fourWeeksForecast = 0;
    let fourWeeksSales = 0;
    let eightWeeksForecast = 0;
    let eightWeeksSales = 0;

    graphData.forEach((dp, index) => {

		let percentage = special ? (isPallet ? dp.vismaPalletPercentage : dp.vismaBoxPercentage) : 1;
        let visma = dp.vismaPalletSales + dp.vismaBoxSales
        
        if (index >= graphData.length - 4) {
            fourWeeksForecast += dp.forecast;
            fourWeeksSales += visma * percentage;
        }

        if (index >= graphData.length - 8) {
            eightWeeksForecast += dp.forecast;
            eightWeeksSales += visma * percentage;
        }
        
    });


    let fourPercentage = fourWeeksSales / fourWeeksForecast - 1;
    let eightPercentage = eightWeeksSales / eightWeeksForecast - 1;

    return (
        <div className="key-value-group">
            <p className="color-gray">prognosis</p>

            <div className="data">

                <div className="group">
                    <span className="units" style={{textTransform: 'lowercase', marginRight: 8}}>4 weeks</span>
                    <div className="row">
                        <div className="number"><Number>{Math.round(fourWeeksForecast)}</Number></div>
                        <span className="units" style={{color: fourPercentage > 0 ? `var(--color-green)` : `var(--color-orange)`, marginLeft: 8}}>
                            <h3 className={`number ${fourPercentage > 0 ? 'positive' : 'negative'} small`}><Number percentage removeFormatting>{fourPercentage}</Number></h3>
                        </span>
                    </div>
                    <h3 className={`number ${fourPercentage > 0 ? 'positive' : 'negative'} small pcs`}>S <Number>{Math.round(fourWeeksSales)}</Number></h3>
                </div>

                <div className="spacer ws"></div>

                <div className="group">
                    <span className="units" style={{textTransform: 'lowercase', marginRight: 8}}>8 weeks</span>
                    <div className="row">
                        <div className="number"><Number>{Math.round(eightWeeksForecast)}</Number></div>
                        <span className="units" style={{color: eightPercentage > 0 ? `var(--color-green)` : `var(--color-orange)`, marginLeft: 8}}>
                            <h3 className={`number ${eightPercentage > 0 ? 'positive' : 'negative'} small`}><Number percentage removeFormatting>{eightPercentage}</Number></h3>
                        </span>
                    </div>
                    <h3 className={`number ${eightPercentage > 0 ? 'positive' : 'negative'} small pcs`}>S <Number>{Math.round(eightWeeksSales)}</Number></h3>
                </div>
                
            </div>

        </div>
    )
}
